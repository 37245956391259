import React from "react"

import IOSFile from "../../images/pdfs/kako-namestiti-lightroom-presets-IOS.pdf"

// Components
import Iframe from "./iframe"

const IOSGuide = () => {
  return (
    <>
      <section className="mb-5">
        <p className="mb-4">
          Za iOS uporabnike, ki imajo iPhone ali iPad je namestitveni postopek
          zelo enostaven. Vse kar potrebuješ je brezplačna Lightroom Mobile
          aplikacija in, da slediš navodilom spodaj.
        </p>
        <p className="font-medium">
          P. S. Če potrebuješ navodila v PDF obliki,{" "}
          <a href={IOSFile} download className="underline">
            klikni tukaj - iOS
          </a>
        </p>
      </section>
      <section className="grid grid-cols-1 gap-6">
        <div>
          <p className="text-2xl font-medium mb-3">Navodila za iOS</p>
          <p className="font-medium text-xl mb-1">
            Prenesi Lightroom Mobile Aplikacijo
          </p>
          <ol className="pl-1">
            <li className="py-2">1. (0:00-0:02) Pojdi v Apple Store</li>
            <li className="py-2">
              2. (0:02-0:12) Na svoj telefon prenesi brezplačno Lightroom Mobile
              Aplikacijo
            </li>
          </ol>
          <Iframe
            src="https://www.youtube.com/embed/lywtmj9d36U"
            title="Brezplačna Lightroom Mobile Aplikacija"
          />
        </div>
        <div>
          <p className="font-medium text-xl mb-1">
            Prenesi Lightroom presete na svoj telefon
          </p>
          <ol className="pl-1">
            <li className="py-2">
              1. (0:00-0:02) Po uspešno opravljenem plačilu boš na svoj email
              naslov prejel/a sporočilo s svojimi Lightroom preseti
            </li>
            <li className="py-2">
              2. (0:02-0:11) Pojdi na email in klikni na sporočilo -{" "}
              <strong>
                "Tvoje naročilo je pripravljeno, poglej svoje izdelke"
              </strong>{" "}
              (Poglej v promocije in vsiljeno pošto)
            </li>
            <li className="py-2">
              3. (0:11-0:15) V tem sporočilu klikni na povezavo oz. povezave (če
              si kupil/a več presetov) in prenos se bo začel
            </li>
            <li className="py-2">
              4. (0:15-0:20) Počakaj, da se prenos konča!
            </li>
          </ol>
          <Iframe
            src="https://www.youtube.com/embed/ejX1S4Rxf4A"
            title="Prenos Lightroom presets na telefon"
          />
        </div>
        <div>
          <p className="font-medium text-xl mb-1">
            Shrani Lightroom presete na svoj telefon
          </p>
          <ol className="pl-1">
            <li className="py-2">
              1. (0:01-0:02) Po končanem prenosu, klikni na{" "}
              <strong>“More...”</strong>
            </li>
            <li className="py-2">
              2. (0:02-0:03) Potem klikni na <strong>“Save to Files”</strong>
            </li>
            <li className="py-2">
              3. (0:03-0:06) Poljubno izberi mapo, ki jo želiš, da se datoteka s
              preseti shrani - Jaz sem izbrala <strong>“On My Iphone”</strong>
            </li>
            <li className="py-2">
              4. (0:06-0:08) Na koncu, klikni na <strong>“Save”</strong> v
              zgornjem desnem kotu
            </li>
            <li className="py-2">
              5. (0:08-0:11) Zdaj pojdi v aplikacijo <strong>“Files”</strong>,
              ki bi že morala biti na tvojem telefonu (V primeru, da aplikacije
              ‘Files” ni na tvojem telefonu, pojdi v Apple Store in jo prenesi
              na svoj telefon)
            </li>
            <li className="py-2">
              6. (0:11-0:12) V <strong>“Files”</strong> aplikaciji, klikni na
              mapo v katero si shranil Lightroom presete - V mojem primeru je to{" "}
              <strong>“On My Iphone”</strong>
            </li>
            <li className="py-2">
              7. (0:12-0:14) Klikni na <strong>.ZIP datoteko</strong>, ki je v
              mapi, da se vse skupaj UN-ZIPA
            </li>
            <li className="py-2">
              8. (0:14-0:16) Ko končaš z UN-ZIPANJEM,{" "}
              <strong>klikni na novo mapo, ki se je pokazala</strong>
            </li>
            <li className="py-2">
              9. (0:16-0:24) Zdaj klikni na ikono v{" "}
              <strong>zgornjem desnem kotu, ki ima 3 pikice</strong> in potem{" "}
              <strong>"Select"</strong> in na koncu{" "}
              <strong>"Select All"</strong> v levem zgornjem kotu (S tem boš
              označil/a vse presete naenkrat)
            </li>
            <li className="py-2">
              10. (0:24-0:28) Še zadnji korak,{" "}
              <strong>klikni na ikono v spodnjem levem kotu (Share) </strong>
              in shrani presete v svojo <strong>Galerijo</strong> s klikom na{" "}
              <strong>"Save Images"</strong> (Poskrbi, da si označil/a vse
              presete)
            </li>

            <li className="py-2">
              11. (0:28-0:34) V galeriji bi moral/a videti vse shranjene presete
            </li>
          </ol>
          <Iframe
            src="https://www.youtube.com/embed/4vn9fO6SrxU"
            title="Shrani Lightroom presete na svoj telefon"
          />
        </div>
        <div>
          <p className="font-medium text-xl mb-1">
            Dodaj in Shrani oz. Ustvari presete v Lightroom aplikaciji
          </p>
          <ol className="pl-1">
            <li className="py-2">
              1. (0:00-0:02) Pojdi v Lightroom aplikacijo na svojem telefonu
            </li>
            <li className="py-2">
              2. (0:02-0:06) Ko se Lightroom odpre, klikni na{" "}
              <strong>“Create Album”</strong> ali <strong>"+"</strong> ikono (V
              primeru, da še nisi prijavljen boš to storil/a zdaj)
            </li>
            <li className="py-2">
              3. (0:06-0:12) Poljubno poimenuj svoj album - Jaz sem ga{" "}
              <strong>“LC Presets”</strong>
            </li>
            <li className="py-2">
              4. (0:12-0:17) Klikni na novo ustvarjen album in dodaj slike s
              klikom na ikono v spodnjem desnem kotu (Dodane slike so tvoji
              preseti)
            </li>
            <li className="py-2">
              5. (0:17-0:19) Klikni na <strong>“From Camera Roll”</strong>
            </li>
            <li className="py-2">
              6. (0:19-0:21) Omogoči Lightroom aplikaciji dostop do tvojih
              fotografij (V primeru, da tega še nisi storil/a)
            </li>
            <li className="py-2">
              7. (0:21-0:22) Označi presete, ki jih želiš dodati v Lightroom
              (Lahko vsakega posebej ali več naenkrat)
            </li>
            <li className="py-2">
              8. (0:23-0:24) Klikni na gumb <strong>“Add”</strong> v spodnjem
              desnem kotu, da se preseti dodajo v album
            </li>
            <li className="py-2">
              9. (0:24-0:27) Ko so preseti dodani v album, klikni na enega izmed
              njih
            </li>
            <li className="py-2">
              10. (0:27-0:29) Potem v zgornjem desnem kotu,{" "}
              <strong>klikni na ikono, ki ima 3 pikice (...)</strong>
            </li>
            <li className="py-2">
              11. (0:29-0:31) Zdaj klikni na <strong>"Create Preset"</strong>
            </li>
            <li className="py-2">
              12. (0:31-0:38) V polje <strong>"Preset Name"</strong> vnesi ime
              svojega preseta - Jaz sem napisala <strong>Amalfitana 10</strong>
            </li>
            <li className="py-2">
              13. (0:38-0:40) Preset se bo shranil med{" "}
              <strong>“User Presets”</strong> (Lokacijo lahko tudi spremeniš s
              klikom na "Preset Group")
            </li>
            <li className="py-2">
              14. (0:40-0:46) Klikni na <strong>"kljukico"</strong> v zgornjem
              desnem kotu, da shraniš preset
            </li>
            <li className="py-2 font-medium">
              ---- Ponovi korake od 9-14 za vsak preset posebej (0:24-0:46) ----
            </li>
          </ol>
          <Iframe
            src="https://www.youtube.com/embed/sdaC-xAZiMA"
            title="Dodaj in Shrani oz. Ustvari presete v Lightroom aplikaciji"
          />
        </div>
        <div>
          <p className="font-medium text-xl mb-1">
            Uporabi presete na svoji fotografiji
          </p>
          <ol className="pl-1">
            <li className="py-2">
              1. (0:00-0:06) Dodaj željeno fotografijo ali fotografije v
              Lightroom aplikacijo (Poglej video)
            </li>
            <li className="py-2">
              2. (0:06-0:11) Na spodnjem “baru” kjer imaš različne funkcije,
              klikni na <strong>"Presets"</strong>
            </li>
            <li className="py-2">
              3. (0:11-0:15) Klikni na <strong>"Yours"</strong>
            </li>
            <li className="py-2">
              4. (0:16-0:19) Potem klikni na <strong>"User Presets"</strong> in
              izberi preset, ki si ga shranil/a
            </li>
            <li className="py-2">
              5. (0:19-0:20) Klikni na <strong>"kljukico"</strong> v spodnjem
              desnem kotu
            </li>
            <li className="py-2">
              6. (0:20-0:22) Zadnji korak, klikni na{" "}
              <strong>"veliko kljukico"</strong> v desnem zgornjem kotu
            </li>
            <li className="py-2 font-medium">
              ---- Če želiš videti Before & After primer, pritisni na sliko in
              se bo spremenila (0:22-0:28) ----
            </li>
          </ol>
          <Iframe
            src="https://www.youtube.com/embed/QX4BP7VBA-Y"
            title="Uporabi presete na svoji fotografiji iOS"
          />
        </div>
      </section>
    </>
  )
}

export default IOSGuide
