import { useState } from "react"

const useToggleInstructions = () => {
  const [type, toggleType] = useState(0)

  return {
    type,
    toggleType,
  }
}

export default useToggleInstructions
