import React from "react"

const Iframe = ({ src, title }) => {
  return (
    <iframe
      className="w-full h-96 my-4"
      src={src}
      title={title}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></iframe>
  )
}

export default Iframe
