import React from "react"

const Toggler = ({ type, toggleType }) => {
  return (
    <div className="grid grid-cols-2 items-center justify-center text-center mb-8">
      <button
        onClick={() => toggleType(0)}
        className={`font-medium px-4 py-2 uppercase border-b-2 transition duration-150 hover:opacity-70 hover:border-primary-110 ${
          type === 0 ? "border-primary-110 bg-primary-30" : "border-transparent"
        }`}
      >
        Iphone
      </button>
      <button
        onClick={() => toggleType(1)}
        className={`font-medium px-4 py-2 uppercase border-b-2 transition duration-150 hover:opacity-70 hover:border-primary-110 ${
          type === 1 ? "border-primary-110 bg-primary-30" : "border-transparent"
        }`}
      >
        Android
      </button>
    </div>
  )
}

export default Toggler
