import React from "react"

import AndroidFile from "../../images/pdfs/kako-namestiti-lightroom-presets-android.pdf"

// Components
import Iframe from "./iframe"

const AndroidGuide = () => {
  return (
    <>
      <section className="mb-5">
        <p className="mb-4">
          Za Android uporabnike je namestitveni postopek zelo enostaven. Vse kar
          potrebuješ je brezplačna Lightroom Mobile in WinZip aplikacija in, da
          slediš navodilom spodaj.
        </p>
        <p className="font-medium">
          P. S. Če potrebuješ navodila v PDF obliki,{" "}
          <a href={AndroidFile} download className="underline">
            klikni tukaj - Android
          </a>
        </p>
      </section>
      <section className="grid grid-cols-1 gap-6">
        <div>
          <p className="text-2xl font-medium mb-3">Navodila za Android</p>
          <p className="font-medium text-xl mb-1">
            Prenesi Lightroom Mobile in WinZip Aplikacijo
          </p>
          <ol className="pl-1">
            <li className="py-2">1. (0:00-0:02) Pojdi v Play Store</li>
            <li className="py-2">
              2. (0:02-0:29) Na svoj telefon prenesi brezplačno Lightroom Mobile
              in WinZip Aplikacijo
            </li>
          </ol>
          <Iframe
            src="https://www.youtube.com/embed/u5X-1OkBoJo"
            title="Brezplačna Lightroom Mobile in WinZip aplikacija"
          />
        </div>
        <div>
          <p className="font-medium text-xl mb-1">
            Prenesi Lightroom presete na svoj telefon
          </p>
          <ol className="pl-1">
            <li className="py-2">
              1. (0:00-0:02) Po uspešno opravljenem plačilu boš na svoj email
              naslov prejel/a sporočilo s svojimi Lightroom preseti
            </li>
            <li className="py-2">
              2. (0:02-0:11) Pojdi na email in klikni na sporočilo -{" "}
              <strong>
                "Tvoje naročilo je pripravljeno, poglej svoje izdelke"
              </strong>{" "}
              (Poglej v promocije in vsiljeno pošto)
            </li>
            <li className="py-2">
              3. (0:11-0:18) V tem sporočilu klikni na povezavo oz. povezave (če
              si kupil/a več presetov) in prenos se bo začel
            </li>
            <li className="py-2">
              4. (0:18-0:26) Počakaj, da se prenos konča!
            </li>
          </ol>
          <Iframe
            src="https://www.youtube.com/embed/yi09vlMK5AA"
            title="Prenos Lightroom presets na telefon"
          />
        </div>
        <div>
          <p className="font-medium text-xl mb-1">
            Shrani Lightroom presete na svoj telefon
          </p>
          <ol className="pl-1">
            <li className="py-2">
              1. (0:01-0:03) Po končanem prenosu, klikni na prenešeno datoteko
            </li>
            <li className="py-2">
              2. (0:03-0:06) Potem klikni na <strong>“WinZip”</strong>{" "}
              aplikacijo
            </li>
            <li className="py-2">3. (0:06-0:09) Označi vse presete v mapi</li>
            <li className="py-2">
              4. (0:09-0:10) Klikni na UN-ZIP ikono v zgornjem desnem kotu
            </li>
            <li className="py-2">
              5. (0:10-0:13) Izberi lokacijo kamor želiš shraniti presete - Jaz
              sem izbrala <strong>"Storage"</strong> ->{" "}
              <strong>"Internal Storage"</strong>
            </li>
            <li className="py-2">
              6. (0:13-0:14) Zdaj v zgornjem desnem kotu klikni na{" "}
              <strong>"+"</strong> ikono
            </li>
            <li className="py-2">
              7. (0:14-0:23) Poljubno poimenuj in ustvari mapo v katero želiš
              shraniti Lightroom presete - Jaz sem jo{" "}
              <strong>"lilicadezpresets"</strong>
            </li>
            <li className="py-2">
              8. (0:24-0:25) Zadnji korak, klikni na{" "}
              <strong>"UNZIP HERE"</strong> gumb v spodnjem desnem kotu
            </li>
            <li className="py-2">9. (0:25-0:26) Uspelo ti je!</li>
          </ol>
          <Iframe
            src="https://www.youtube.com/embed/sau_XvgWmOU"
            title="Shrani Lightroom presete na svoj telefon"
          />
        </div>
        <div>
          <p className="font-medium text-xl mb-1">
            Dodaj in Shrani oz. Ustvari presete v Lightroom aplikaciji
          </p>
          <ol className="pl-1">
            <li className="py-2">
              1. (0:00-0:01) Pojdi v Lightroom aplikacijo na svojem telefonu
            </li>
            <li className="py-2">
              2. (0:01-0:04) Ko se Lightroom odpre, klikni na{" "}
              <strong>“New Item”</strong> ali <strong>"+"</strong> ikono (V
              primeru, da še nisi prijavljen boš to storil/a zdaj)
            </li>
            <li className="py-2">
              3. (0:04-0:11) Poljubno poimenuj svoj album - Jaz sem ga{" "}
              <strong>“LC Presets”</strong>
            </li>
            <li className="py-2">
              4. (0:11-0:14) Klikni na novo ustvarjen album in dodaj slike s
              klikom na ikono v spodnjem desnem kotu (Dodane slike so tvoji
              preseti)
            </li>
            <li className="py-2">
              5. (0:14-0:16) Klikni na <strong>“Device”</strong>
            </li>
            <li className="py-2">
              6. (0:17-0:18) Omogoči Lightroom aplikaciji dostop do tvojih
              fotografij (V primeru, da tega še nisi storil/a)
            </li>
            <li className="py-2">
              7. (0:18-0:20) Označi presete, ki jih želiš dodati v Lightroom
              (Lahko vsakega posebej ali več naenkrat)
            </li>
            <li className="py-2">
              8. (0:21-0:22) Klikni na gumb <strong>“Add”</strong> v spodnjem
              desnem kotu, da se preseti dodajo v album
            </li>
            <li className="py-2">
              9. (0:23-0:29) Ko so preseti dodani v album, klikni na enega izmed
              njih
            </li>
            <li className="py-2">
              10. (0:29-0:30) Potem v zgornjem desnem kotu,{" "}
              <strong>klikni na ikono, ki ima 3 pikice (...)</strong>
            </li>
            <li className="py-2">
              11. (0:30-0:31) Zdaj klikni na <strong>"Create Preset"</strong>
            </li>
            <li className="py-2">
              12. (0:32-0:44) V polje <strong>"Preset Name"</strong> vnesi ime
              svojega preseta - Jaz sem napisala <strong>Amalfitana 10</strong>
            </li>
            <li className="py-2">
              13. (0:44-0:45) Preset se bo shranil med{" "}
              <strong>“User Presets”</strong> (Lokacijo lahko tudi spremeniš s
              klikom na "Preset Group")
            </li>
            <li className="py-2">
              14. (0:45-0:47) Klikni na <strong>"kljukico"</strong> v zgornjem
              desnem kotu, da shraniš preset
            </li>
            <li className="py-2 font-medium">
              ---- Ponovi korake od 9-14 za vsak preset posebej (0:24-0:46) ----
            </li>
          </ol>
          <Iframe
            src="https://www.youtube.com/embed/LAHMxRxs2-4"
            title="Dodaj in Shrani oz. Ustvari presete v Lightroom aplikaciji"
          />
        </div>
        <div>
          <p className="font-medium text-xl mb-1">
            Uporabi presete na svoji fotografiji
          </p>
          <ol className="pl-1">
            <li className="py-2">
              1. (0:00-0:21) Dodaj željeno fotografijo ali fotografije v
              Lightroom aplikacijo (Poglej video)
            </li>
            <li className="py-2">
              2. (0:22-0:23) Na spodnjem “baru” kjer imaš različne funkcije,
              klikni na <strong>"Presets"</strong>
            </li>
            <li className="py-2">
              3. (0:23-0:27) Klikni na <strong>"Yours"</strong>
            </li>
            <li className="py-2">
              4. (0:27-0:30) Potem klikni na <strong>"User Presets"</strong> in
              izberi preset, ki si ga shranil/a
            </li>
            <li className="py-2">
              5. (0:30-0:32) Zadnji korak, klikni na <strong>"kljukico"</strong>{" "}
              v zgornjem desnem kotu
            </li>
            <li className="py-2 font-medium">
              ---- Če želiš videti Before & After primer, pritisni na sliko in
              se bo spremenila (0:32-0:36) ----
            </li>
          </ol>
          <Iframe
            src="https://www.youtube.com/embed/iw2vhoLbQ_g"
            title="Uporabi presete na svoji fotografiji iOS"
          />
        </div>
      </section>
    </>
  )
}

export default AndroidGuide
