import React from "react"

// Components
import Layout from "../components/layout"
import Seo from "../components/seo"

import Toogler from "../components/instructions/toggler"
import IOSGuide from "../components/instructions/iosGuide"
import AndroidGuide from "../components/instructions/androidGuide"
import CtaBoxes from "../components/promotions/ctaBoxes"
import { SectionHeading } from "../components/headings/index"
// Hooks
import useToggleInstructions from "../hooks/useToggleInstructions"

const HowToPage = ({ location }) => {
  const { type, toggleType } = useToggleInstructions()

  return (
    <Layout path={location.pathname}>
      <Seo
        title="Navodila"
        lang="sl"
        description="Mobile Adobe Lightroom Presets - Urejaj slike z enim klikom - Top kvaliteta in doživljenski dostop. 24/7 podpora."
      />
      <section className="max-w-screen-md mx-auto px-4 pb-8 pt-10 sm:py-16">
        <SectionHeading title="howTo" />
        <div>
          <p className="text-xl font-medium mb-3">
            Kako namestiti Lightroom Presete?
          </p>
          <p className="mb-5">
            Spodaj imaš STEP BY STEP navodila za čisto vse korake.{" "}
            <span role="img" aria-label="check mark">
              ✅
            </span>
          </p>
        </div>
        <Toogler type={type} toggleType={toggleType} />
        {type === 0 ? <IOSGuide /> : <AndroidGuide />}
        <div className="mt-5 text-center">
          <p>Še vedno potrebuješ pomoč? Ni panike!</p>
          Kontaktiraj me na{" "}
          <a
            href="mailto:presets@lilicadez.com"
            className="text-blue-600 underline"
          >
            presets@lilicadez.com
          </a>{" "}
          ali{" "}
          <a
            href="https://instagram.com/lilicadez"
            target="_blank"
            rel="noreferrer"
            className="text-blue-600 underline"
          >
            Instagram DM
          </a>{" "}
          <span role="img" aria-label="heart icon">
            ❤️
          </span>
        </div>
      </section>
      {/* <section className="max-w-screen-xl mx-auto px-4 pb-10 sm:pb-16">
        <CtaBoxes />
      </section> */}
    </Layout>
  )
}

export default HowToPage
